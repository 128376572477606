import { API_URL } from "./config";

export const fetchRoutes = async () => {
    try {
        const response = await fetch(`${API_URL}/routes`);
        const data = await response.json();

        return data;
    } catch (error) {
        console.error("Routes fetching error:", error);

        return [];
    }
};

const difficultyToClassName = (difficulty) =>
    difficulty === "Łatwy"
        ? "easy"
        : difficulty === "Średni"
            ? "medium"
            : difficulty === "Trudny"
                ? "hard"
                : undefined;

export const createRouteCard = (route) => {
    const routeEl = document.getElementById("template-route-card").content.cloneNode(true);

    const imageEl = routeEl.querySelector(".route__image");
    const difficultyEl = routeEl.querySelector(".route__difficulty");
    const favoritesEl = routeEl.querySelector(".route__favorites-value");
    const titleEl = routeEl.querySelector(".route__title");
    // const descriptionEl = routeEl.querySelector(".route__description");
    const locationEl = routeEl.querySelector(".route__location-value");
    const distanceEl = routeEl.querySelector(".route__distance-value");
    const challengesEl = routeEl.querySelector(".route__challenges-value");

    imageEl.src = `${API_URL}${route.thumb.url}`;
    difficultyEl.textContent = route.difficulty.name;
    difficultyEl.classList.add(`route__difficulty--${difficultyToClassName(route.difficulty.name)}`);
    favoritesEl.textContent = route.favoritescount;
    titleEl.textContent = route.name;
    // descriptionEl.textContent = route.description;
    locationEl.textContent = route.location ?? route.locations[0]?.name ?? "Rynarzewo/Zamość";
    distanceEl.textContent = `${(Number(route.distance) / 1000).toFixed(1)} km`;
    challengesEl.textContent = route.places.length;

    return routeEl;
};

export const createRoutesSection = (routes) => {
    const routesEl = document.getElementById("template-routes").content.cloneNode(true);
    const routesSliderEl = routesEl.querySelector(".routes__slider");

    routesSliderEl.append(...routes.map(createRouteCard));

    return routesEl;
};

export const init = async () => {
    const routes = await fetchRoutes();

    if (routes.length > 0) {
        const routesSectionEl = createRoutesSection(routes);
        const processSectionEl = document.querySelector(".process");

        processSectionEl.parentNode.insertBefore(routesSectionEl, processSectionEl);
    }
};
