import * as AOS from "aos";
import "slick-carousel";
import $ from "jquery";
import * as routes from "./routes";

const main = async () => {
    AOS.init();
    $(".process__slider").slick({
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false
    });

    await routes.init();
};

main();
